import React, { useState } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import VideoLibrary from "./pages/VideoLibrary";
import Navbar from "./components/Navbar";
import PaymentPage from "./pages/PaymentPage";
import TestImage from "./assets/images/logoPlaceholder.png";
import ProtectedArea from "./components/ProtectedArea";

// Replace with fetched data:
interface MyObject {
  title: string;
  image: string;
}

const arrayOfObjects: MyObject[] = [
  {
    title: "Horizontal(16:9)",
    image: TestImage,
  },
  {
    title: "Square(1:1)",
    image: TestImage,
  },
  {
    title: "Vertical(4:5)",
    image: TestImage,
  },
];

function App() {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [paid, setPaid] = useState<boolean>(false);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState<boolean[]>(
    Array(arrayOfObjects.length).fill(false)
  );

  const handleLogin = (): void => {
    setLoggedIn(true);
  };

  const handleCheckboxChange = (index: number): void => {
    setCheckedCheckboxes((prevState) => {
      const newCheckedCheckboxes = [...prevState];
      newCheckedCheckboxes[index] = !newCheckedCheckboxes[index];
      return newCheckedCheckboxes;
    });
  };

  return (
    <>
      <div className="h-screen">
        <Navbar />
        {/* {!isLoggedIn && <Login onLogin={handleLogin} />} */}
        <Routes>
          <Route
            path="/"
            element={
              !isLoggedIn ? (
                <Login onLogin={handleLogin} />
              ) : (
                <Navigate to="/library" />
              )
            }
          />
          <Route
            path="/library"
            element={
              <ProtectedArea>
                <VideoLibrary
                  arrayOfObjects={arrayOfObjects}
                  checkedCheckboxes={checkedCheckboxes}
                  handleCheckboxChange={handleCheckboxChange}
                  paid={paid}
                />
              </ProtectedArea>
            }
          />
          <Route
            path="/library/payment"
            element={
              <ProtectedArea>
                <PaymentPage
                  checkedCheckboxes={checkedCheckboxes}
                  setCheckedCheckboxes={setCheckedCheckboxes}
                  handleCheckboxChange={handleCheckboxChange}
                  setPaid={setPaid}
                />
              </ProtectedArea>
            }
          />
        </Routes>
      </div>
      {/* <PaymentPage /> */}
    </>
  );
}

export default App;
