import axios from "axios";

// const API_BASE_URL = 'https://api.dev.gradcut.com/'; // Replace with your actual API base URL
const API_BASE_URL =
  "https://5m5oi6lv7a.execute-api.us-west-2.amazonaws.com/stage/"; // Replace with your actual API base URL

class APIService {
  api: any;
  token: string | null;
  constructor() {
    this.api = axios.create({
      baseURL: API_BASE_URL,
      // You can add additional Axios configuration options here, e.g. headers, timeout, etc.
    });
    this.token = localStorage.getItem("token");
  }

  async signup(data: any): Promise<any> {
    try {
      const response = await this.api.post("auth/sign-up", data);
      return response;
    } catch (error) {
      return error;
    }
  }

  async login(data: any) {
    try {
      const response = await this.api.post("student/login", data);
      return response;
    } catch (error) {
      console.log("error ", error);
      return error;
    }
  }

  async verifyOtp(data: any) {
    try {
      const response = await this.api.post("student/verify", data);
      return response;
    } catch (error) {
      console.log("error ", error);
      return error;
    }
  }

  async resendOtp(data: any) {
    try {
      const response = await this.api.post("student/verify", data);
      return response;
    } catch (error) {
      console.log("error ", error);
      return error;
    }
  }

  async getCuts() {
    try {
      const response = await this.api.get("student/cuts", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return response;
    } catch (error) {
      console.log("error ", error);
      return error;
    }
  }

  async payment(data: any) {
    try {
      const response = await this.api.post("student/payment", data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return response;
    } catch (error) {
      console.log("error ", error);
      return error;
    }
  }
}

export default new APIService();
