import { FC, useState, ChangeEvent, useCallback } from "react";
import CountdownTimer from "../DownDownTimmer/DownTownTimmer";
import CircularProgressWithLabel from "../CircularProgressWithLabel";

interface OtpProps {
  handleLogin: (otpValues: string[]) => void;
  login: () => void;
  loading: boolean;
  loginData: any;
  email: string;
}

const Otp: FC<OtpProps> = ({
  handleLogin,
  loading,
  loginData,
  email,
  login,
}) => {
  const [otpValues, setOtpValues] = useState<string[]>(new Array(6).fill(""));
  const handleOtpInputChange = (index: number, value: string): void => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
  };

  const handleTimerExpire = useCallback(() => {
    console.log("Timer expired!");
    // Perform any action when the timer reaches zero
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="font-bold flex items-center justify-center">
        Verify Your Account
      </h2>
      <h1 className="font-regular text-center text-sm lg:text-base">
        We have sent the one time pass code (OTP) to {email}
      </h1>
      <h1 className="font-regular text-center text-sm lg:text-base">
        <span className="font-bold">Enter the code below</span> to confirm your
        email address
      </h1>
      <div className="flex items-center justify-center mt-4">
        {otpValues.map((value, index) => (
          <input
            key={index}
            className="w-10 h-10 md:w-12 md:h-12 lg:w-16 lg:h-16 border border-neutral-400 rounded-lg shadow-2xl bg-neutral-200 mx-2 text-center text-base md:text-xl lg:text-2xl focus:outline-none focus:border-primary"
            type="text"
            maxLength={1}
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleOtpInputChange(index, e.target.value)
            }
          />
        ))}
      </div>
      <h1 className="font-regular text-center text-sm lg:text-base mt-5">
        Code expires in:
        {/* <span className="font-bold text-primary">03:00</span> */}
        <CountdownTimer initialTime={300} onTimerExpire={handleTimerExpire} />
      </h1>
      <div className="flex items-center justify-center w-1/3 mt-8">
        <button
          className="bg-primary text-white font-bold py-1 px-6 w-full rounded-lg focus:outline-none focus:shadow-outline"
          type="button"
          onClick={() => handleLogin(otpValues)}
        >
          {loading ? <CircularProgressWithLabel /> : "Verify"}
        </button>
      </div>
      <h1 className="font-regular text-center text-sm lg:text-base mt-5">
        Didn't get the code?
        <span
          className="font-bold text-primary uppercase"
          style={{ cursor: "pointer" }}
          onClick={login}
        >
          {" "}
          RESEND CODE
        </span>
      </h1>
    </div>
  );
};

export default Otp;
