import { FC, useState } from "react";
import logo from "../../assets/images/logo.png";
import Otp from "../../components/OTP";
import APIService from "../../network/APIService";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";

interface LoginProps {
  onLogin: () => void;
}

interface LoginData {
  firstName: string;
  lastName: string;
  universityEmail: string;
  studentId: string;
}

const Login: FC<LoginProps> = ({ onLogin }) => {
  const [activeTab, setActiveTab] = useState<string | null>("studentID"); //studentID
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [loginData, setLoginData] = useState<LoginData>({
    firstName: "",
    lastName: "",
    universityEmail: "",
    studentId: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleTabClick = (tab: string): void => {
    setActiveTab(activeTab === tab ? tab : tab);
  };

  const handleLogin = async () => {
    onLogin();
  };

  const handleOnChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const validateLoginData = () => {
    if (
      loginData.firstName === "" ||
      loginData.lastName === "" ||
      loginData.universityEmail === ""
    ) {
      alert("Please fill in all the fields");
    } else {
      login();
    }
  };

  const login = async () => {
    setLoading(true);
    try {
      const response = await APIService.login(loginData);
      console.log("object, response", response);
      if (response && response.status === 200) {
        setLoading(false);
        setEmail(response.data);
        // localStorage.setItem('token', response.data.token);

        setLoggedIn(true);
      } else {
        // alert(response.response.data);
        setLoading(false);
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const validateOtpInput = (otp: any) => {
    const otpValues = otp.filter((value: string) => value !== "");
    // alert(otpValues.length);
    if (otpValues.length < 6) {
      alert("Please fill in the OTP field");
    } else {
      verifyOtp(otp);
    }
  };
  const verifyOtp = async (otp: any) => {
    setLoading(true);
    try {
      const mergeOtp = otp.map((value: string) => value).join("");
      const obj = {
        otp: mergeOtp,
      };
      const response = await APIService.verifyOtp(obj);
      console.log("object, response", response);
      if (response && response.status === 200) {
        setLoading(false);
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("token", response.data.token);
        handleLogin();
      } else {
        // alert(response.response.data);
        setLoading(false);
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div>
      <h1 className="flex items-center justify-center m-4 p-2 font-bold text-base text-center lg:text-2xl">
        View your Graduation Videos
      </h1>
      <div className="flex flex-col sm:flex-row items-center justify-center m-2 p-2 lg:m-4">
        {/* Logo */}
        <div className="m-4 mt-0 p-0 lg:m-4 lg:p-4 w-1/2 lg:w-auto flex align-center justify-center">
          <img src={logo} alt="Institution Logo" />
        </div>
        {/* Login fields */}
        {loggedIn ? (
          // render OTP
          <Otp
            loading={loading}
            handleLogin={validateOtpInput}
            loginData={loginData}
            email={email}
            login={login}
          />
        ) : (
          <div className="flex flex-col items-center justify-center">
            <h2 className="font-bold flex items-center justify-center">
              Welcome
            </h2>
            <h1 className="font-regular text-center text-sm lg:text-base">
              To login, enter your Full Name and Student ID/ University Email
            </h1>
            <div className="w-full font-bold">
              <ul className="w-full flex items-center justify-center gap-8 mt-6 border border-t-0 border-r-0 border-l-0 border-b-ash">
                {/* <li
                  className={`cursor-pointer ${
                    activeTab === "studentID"
                      ? "text-primary border-b-2 border-primary"
                      : "text-black border-b-2 border-white"
                  }`}
                  onClick={() => handleTabClick("studentID")}
                >
                  Student ID
                </li> */}
                <li
                  className={`cursor-pointer ${
                    activeTab === "universityEmail"
                      ? "text-primary border-b-2 border-primary"
                      : "text-black border-b-2 border-white"
                  }`}
                  onClick={() => handleTabClick("universityEmail")}
                >
                  University Email
                </li>
              </ul>
            </div>
            {/* Form */}
            <div className="w-full max-w-xs">
              <form className="bg-white rounded px-8 pt-6 mb-4 lg:pb-8 lg:mb-4 flex flex-col items-center justify-center">
                <div className="w-full mb-2 flex gap-4 mb-3">
                  <div>
                    <label
                      className="block text-black text-sm font-bold mb-1 lg:mb-2"
                      htmlFor="firstName"
                    >
                      First Name
                    </label>
                    <input
                      className="shadow appearance-none border w-full py-1 px-3 lg:py-2 text-gray leading-tight focus:outline-none focus:shadow-outline"
                      id="firstName"
                      type="text"
                      name="firstName"
                      value={loginData.firstName}
                      onChange={handleOnChangeInput}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-black text-sm font-bold mb-1 lg:mb-2"
                      htmlFor="lastName"
                    >
                      Last Name
                    </label>
                    <input
                      className="shadow appearance-none border w-full py-1 px-3 lg:py-2 text-gray leading-tight focus:outline-none focus:shadow-outline"
                      id="lastName"
                      type="text"
                      name="lastName"
                      value={loginData.lastName}
                      onChange={handleOnChangeInput}
                    />
                  </div>
                </div>
                {activeTab === "studentID" && (
                  <div className="mb-6">
                    <label
                      className="block text-black text-sm font-bold mb-1 lg:mb-2"
                      htmlFor="studentID"
                    >
                      <span className="text-red-500">*</span>Student ID
                    </label>
                    <input
                      className="shadow appearance-none border border-gray w-full py-1 px-3 lg:py-2 text-gray mb-3 leading-tight focus:outline-none focus:shadow-outline"
                      id="studentID"
                      type="text"
                      name="studentId"
                      value={loginData.studentId}
                      onChange={handleOnChangeInput}
                    />
                  </div>
                )}
                <div className="mb-6">
                  <label
                    className="block text-black text-sm font-bold mb-1 lg:mb-2"
                    htmlFor="email"
                  >
                    <span className="text-red-500">*</span>University Email
                  </label>
                  <input
                    className="shadow appearance-none border border-gray w-full py-1 px-3 lg:py-2 text-gray mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    name="universityEmail"
                    value={loginData.universityEmail}
                    onChange={handleOnChangeInput}
                  />
                </div>
                {activeTab === "universityEmail" && (
                  <div className="mb-6">
                    <label
                      className="block text-black text-sm font-bold mb-1 lg:mb-2"
                      htmlFor="email"
                    >
                      <span className="text-red-500">*</span>University Email
                    </label>
                    <input
                      className="shadow appearance-none border border-gray w-full py-1 px-3 lg:py-2 text-gray mb-3 leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      type="email"
                      name="universityEmail"
                      value={loginData.universityEmail}
                      onChange={handleOnChangeInput}
                    />
                  </div>
                )}
                {/* Button */}
                <div className="flex items-center justify-between">
                  <button
                    className="bg-primary text-white font-bold py-1 px-6 rounded-2xl focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={validateLoginData}
                  >
                    {loading ? <CircularProgressWithLabel /> : "Login"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
