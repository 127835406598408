import { FC } from "react";
import closeIcon from '../../assets/images/clear.svg';
import snapchatIcon from '../../assets/images/snapchat.svg';
import instagramIcon from '../../assets/images/instagram.svg';
import wechatIcon from '../../assets/images/wechat-9 1.svg';
import facebookIcon from '../../assets/images/facebook.svg';
import linkedinIcon from '../../assets/images/linkedIn.svg';

interface ShareProps {
    setShareModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Share: FC<ShareProps> = ({ setShareModal }) => {
    return (
        <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50">
                <div className="w-5/6 lg:w-1/4 m-2">
                    {/* content */}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full p-3 bg-white outline-none focus:outline-none">
                        {/* header */}
                        <div className="flex justify-between items-center my-2">
                            <h3 className="text-3xl font-semibold">
                                Share
                            </h3>
                            <button onClick={() => setShareModal(false)}>
                                <img src={closeIcon} alt="close icon" />
                            </button>
                        </div>
                        {/* body */}
                        <p className="text-sm my-4">
                            Celebrate your achievements and share the joy. Effortlessly share your graduation moments with friends and family on social media.
                        </p>
                        {/* Share section */}
                        <div className="flex flex-col gap-4 mb-4">
                            <div className="flex justify-between items-center gap-4">
                                <div className="flex justify-center items-center gap-4">
                                    <img src={facebookIcon} alt="facebook" />
                                    <p>Facebook</p>
                                </div>
                                <button className="bg-primary text-white inline-flex items-center py-1 px-6 text-sm font-medium text-center rounded-2xl">Share</button>
                            </div>
                            <div className="flex justify-between items-center gap-4">
                                <div className="flex justify-center items-center gap-4">
                                    <img src={instagramIcon} alt="instagram" />
                                    <p>Instagram</p>
                                </div>
                                <button className="bg-primary text-white inline-flex items-center py-1 px-6 text-sm font-medium text-center rounded-2xl">Share</button>
                            </div>
                            <div className="flex justify-between items-center gap-4">
                                <div className="flex justify-center items-center gap-4">
                                    <img src={snapchatIcon} alt="snapchat" />
                                    <p>Snapchat</p>
                                </div>
                                <button className="bg-primary text-white inline-flex items-center py-1 px-6 text-sm font-medium text-center rounded-2xl">Share</button>
                            </div>
                            <div className="flex justify-between items-center gap-4">
                                <div className="flex justify-center items-center gap-4">
                                    <img src={linkedinIcon} alt="linkedIn" />
                                    <p>LinkedIn</p>
                                </div>
                                <button className="bg-primary text-white inline-flex items-center py-1 px-6 text-sm font-medium text-center rounded-2xl">Share</button>
                            </div>
                            <div className="flex justify-between items-center gap-4">
                                <div className="flex justify-center items-center gap-4">
                                    <img src={wechatIcon} alt="weChat" />
                                    <p>WeChat</p>
                                </div>
                                <button className="bg-primary text-white inline-flex items-center py-1 px-6 text-sm font-medium text-center rounded-2xl">Share</button>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    );
};

export default Share;