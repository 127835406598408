import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Share from "../../components/Share";
import CopyLink from "../../components/CopyLink";
import tickIcon from "../../assets/images/Approve(Tick).svg";
import APIService from "../../network/APIService";
import TestImage from "../../assets/images/logoPlaceholder.png";
import { saveAs } from "file-saver";

interface MyObject {
  title: string;
  image: string;
}

interface VideoLibraryProps {
  arrayOfObjects: MyObject[];
  checkedCheckboxes: boolean[];
  handleCheckboxChange: (index: number, id: string) => void;
  paid: boolean;
}

const VideoLibrary: FC<VideoLibraryProps> = ({
  arrayOfObjects,
  checkedCheckboxes,
  handleCheckboxChange,
  paid,
}) => {
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [copyModal, setCopyModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [cutData, setCutData] = useState<any[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleProceedToPayment = (): void => {
    navigate("/library/payment");
  };

  const isButtonDisabled =
    checkedCheckboxes.length === 0 || !checkedCheckboxes.some((value) => value);

  useEffect(() => {
    fetchCuts();
  }, []);

  const fetchCuts = async () => {
    setLoading(true);
    try {
      const response = await APIService.getCuts();
      console.log("object, response", response);
      if (response && response.status === 200) {
        setLoading(false);
        setCutData(response.data);
        // const data: any = response.data.filter((d: any) => !d.paymentVerified);
        // setDisabled(data.length > 0);
      } else {
        // alert(response.response.data);
        setLoading(false);
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const downloadVideo = async (path: string, name: string) => {
    try {
      const response = await fetch(path);
      const blob = await response.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = name;
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the DOM
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading video:", error);
    }
  };
  return (
    <div>
      {(shareModal || copyModal) && (
        <div className="fixed inset-0 bg-black opacity-50 z-50"></div>
      )}
      <div className="flex items-center justify-center">
        {paid && (
          <div className="bg-primary rounded-full w-5 h-5 lg:w-10 lg:h-10">
            <img
              src={tickIcon}
              alt="tickIcon"
              className="w-5 h-5 lg:w-10 lg:h-10"
            />
          </div>
        )}
        <h1 className="flex items-center justify-center m-4 p-2 font-bold text-base text-center lg:text-2xl">
          {"View your Graduation Videos"}
        </h1>
      </div>
      <div className="my-2 mx-6 lg:my-8 lg:mx-20 p-2">
        <div className="flex items-center justify-end pb-2">
          <button
            //   className="bg-primary text-white text-sm lg:text-base font-bold py-1 px-6 rounded-lg focus:outline-none focus:shadow-outline"
            className={`text-white text-sm lg:text-base font-bold py-1 px-6 rounded-lg focus:outline-none focus:shadow-outline bg-primary`}
            type="button"
            onClick={handleProceedToPayment}
            disabled={disabled}
          >
            Proceed to Payment
          </button>
        </div>
        {paid && (
          <h3 className="flex items-center m-4 lg:mb-10 p-2 font-bold text-sm text-center lg:text-xl">
            Here are your download links:
          </h3>
        )}
        {/* Cards */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-20 mt-4">
          {cutData &&
            cutData.length > 0 &&
            cutData.map(
              (object, index) => (
                // (paid ? checkedCheckboxes[index] : true) && (
                <div className="w-full bg-white rounded-lg shadow-2xl">
                  <div className="flex justify-start font-bold px-4 py-4">
                    <h3>{object.name}</h3>
                  </div>
                  <div className="flex flex-col items-center justify-center px-4 py-4">
                    {/* <img
                      className="w-24 h-24 mb-3 shadow-lg object-cover"
                      src={TestImage}
                      alt="tempImage"
                    /> */}
                    <video controls className="mb-3 shadow-lg object-cover">
                      <source src={object.path} type="video/mp4" />
                    </video>
                    <div className="flex w-full justify-center my-4">
                      <button
                        type="button"
                        className="inline-flex items-center py-2 px-2 text-sm font-medium text-center text-gray-900 bg-white border border-black rounded-lg"
                        style={{ opacity: paid ? 1 : 0.5 }}
                        onClick={() => {
                          downloadVideo(object.path, object.name);
                          //   window.open(object.path, "_blank");
                        }}
                      >
                        Download
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center px-3 text-sm font-medium text-center text-gray-900 bg-white border border-black rounded-lg ml-3"
                        disabled={!paid}
                        style={{ opacity: paid ? 1 : 0.5 }}
                        onClick={() => setShareModal(true)}
                      >
                        Share
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center px-2 text-sm font-medium text-center text-gray-900 bg-white border border-black rounded-lg ml-3"
                        disabled={!paid}
                        style={{ opacity: paid ? 1 : 0.5 }}
                        onClick={() => setCopyModal(true)}
                      >
                        Copy Link
                      </button>
                    </div>
                    {/* Checkbox */}
                    {object?.path === undefined && (
                      <div className="flex items-center my-4">
                        <input
                          id={`checkbox-${index}`}
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-ash rounded focus:ring-2 accent-primary"
                          checked={checkedCheckboxes[index]}
                          onChange={() =>
                            handleCheckboxChange(index, object.id)
                          }
                        />
                        <label
                          htmlFor={`checkbox-${index}`}
                          className="ml-2 text-sm font-medium text-black"
                        >
                          I want to Download my video without watermarks
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )
              // )
            )}
        </div>
      </div>
      {/* <Share setShareModal={setShareModal} /> */}
      {shareModal && <Share setShareModal={setShareModal} />}
      {copyModal && <CopyLink setCopyModal={setCopyModal} />}
    </div>
  );
};

export default VideoLibrary;
